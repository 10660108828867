import ObjectsSearchCard from '@components/Search/Cards/ObjectsSearchCard';
import UserSearchCard from '@components/Search/Cards/UserSearchCard';
import styles from '@components/Search/Search.styles';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { BackendObjectPKG } from '@service/backend/model/objects';
import { SearchHighlight } from '@service/model';
import React, { useEffect, useState } from 'react';

interface Props {
  keyword: string;
  closeSuggestions?: () => void;
}

const CardRender = {
  [BackendObjectPKG.TICKET]: (props, closeSuggestions) => <ObjectsSearchCard object={props}
                                                                             closeSuggestions={closeSuggestions} />,
  [BackendObjectPKG.POST]: (props, closeSuggestions) => <ObjectsSearchCard object={props}
                                                                           closeSuggestions={closeSuggestions} />,
  [BackendObjectPKG.USER]: (props, closeSuggestions) => <UserSearchCard user={props}
                                                                        closeSuggestions={closeSuggestions} />,
  [BackendObjectPKG.DOCUMENT]: (props, closeSuggestions) => <ObjectsSearchCard object={props}
                                                                               closeSuggestions={closeSuggestions} />,
  [BackendObjectPKG.ONLINE_EVENT]: (props, closeSuggestions) => <ObjectsSearchCard object={props}
                                                                                   closeSuggestions={closeSuggestions} />,

};

const Title = {
  [BackendObjectPKG.TICKET]: () => 'Consultas',
  [BackendObjectPKG.USER]: () => 'Usuarios',
  [BackendObjectPKG.POST]: () => 'Publicaciones',
  [BackendObjectPKG.DOCUMENT]: () => 'Documentos',
  [BackendObjectPKG.ONLINE_EVENT]: () => 'Eventos',
};


const PreviewResults: React.VFC<Props> = (props) => {

  const classes = styles();

  const useLatestSearch = (url, keyword) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const fetchData = async () => {
        try {
          const response = await fetch(`${url}?keyword=${keyword}`, { signal });
          const responseData = await response.json();
          setData(responseData);
        } catch (error) {
          if (error.name === 'AbortError') {
            console.warn('Request aborted');
          } else {
            console.error('Error:', error);
          }
        }
      };

      fetchData();

      return () => {
        abortController.abort();
      };
    }, [url, keyword]);

    return data;
  };

  const url = '/api/search/highlight';
  const data = useLatestSearch(url, props.keyword);

  const transform = (results: SearchHighlight[]) => {
    const resultHighlight = results.map(result => ({ ...result, pkg: result.pkg }))
      .reduce((acc, act) => {
        return {
          ...acc,
          [act.pkg]: [...(acc[act.pkg] || []), act],
        };
      }, {});

    return Object.entries(resultHighlight);
  };

  let transformResults;

  if (data && data.matchs) {
    transformResults = transform(data.matchs);
  }

  return (

    <div>
      {transformResults && transformResults.map((result, index) => {
        return (
          <Box key={index}>
            <OnDesktop>

              <Typography variant='body2'
                          color='textSecondary'>{Title[result[0]] && Title[result[0]](result)}</Typography>
              {result[1].map((item, index) => {
                return (
                  <Box key={index}>
                    {CardRender[result[0]] && CardRender[result[0]](item, props.closeSuggestions)}
                  </Box>
                );
              })}

              <Divider variant='fullWidth' />
            </OnDesktop>
            <OnMobile>
              {result[1].map((item, index) => {
                return (
                  <Box display='flex' key={index} marginTop='5px'>
                    <Chip className={classes.chip} label={Title[result[0]] && Title[result[0]](result)} />
                    {CardRender[result[0]] && CardRender[result[0]](item)}
                  </Box>
                );
              })}

              <Divider variant='fullWidth' />
            </OnMobile>
          </Box>

        );
      })}
    </div>

  );


};

export default PreviewResults;
