import Button from '@components/AcoButtons/Button';
import ACOLink from '@components/ACOLink/ACOLink';
import { Avatar } from '@components/Avatar/Avatar';
import { ObjectsSearchCardProps } from '@components/Search/Cards/ObjectsSearchCard';
import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React from 'react';

interface UserSearchProps {
  user: ObjectsSearchCardProps,
  closeSuggestions: () => void
}


function renderBtns(state: boolean, title: string, icon: OverridableComponent<SvgIconTypeMap>, className: string, func: () => void) {
  const Icon = icon;

  return (<>
      <Button disableRipple startIcon={<Icon />} className={className} onClick={func}> {title}</Button>
    </>
  );

}


const UserSearchCard: React.VFC<UserSearchProps> = (props) => {

  const { user: loggedUser, isLogged } = useSession();
  const { snackbars } = useCustomSnackbar();

  const [stateFollow, setStateFollow] = React.useState(false);

/*  {
    loggedUser &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const url = '/api/user/red/followUser';

      axios.get<ResponseResult>(`${url}?usuario_logueado_id=${loggedUser.id}&usuario_id=${props.user.id}`).then((res) => {
        setStateFollow(res.data.success);
      });
    }, [loggedUser, props]);
  }*/

  /*TODO: Acomodar para que esto funcione con el hook*/

  const unfollow = (usuarioId: number, completeName: string) => {
    const data = { usuarioId };

    if (isLogged) {
      axios.post<ResponseResult>('/api/user/red/followers', data)
        .then((res) => {
          if (res.data.success) {
            snackbars.showError(`Dejaste de seguir a ${completeName}`);
            setStateFollow(!stateFollow);
          } else {
            snackbars.showWarning('Error interno');
          }
        });
    } else {
      snackbars.showWarning('Es necesario iniciar sesión para seguir a un usuario');
    }
  };

  const follow = (usuarioId: number, completeName: string) => {
    const data = { usuarioId };

    if (isLogged) {
      axios.post<ResponseResult>('/api/user/red/following', data)
        .then((res) => {
          if (res.data.success) {
            snackbars.showSuccess(`Comenzaste a seguir a ${completeName}`);
            setStateFollow(!stateFollow);
          } else {
            snackbars.showWarning('Error interno');
          }
        });
    } else {
      snackbars.showWarning('Es necesario iniciar sesión para seguir a un usuario');
    }
  };


  return (
    <Box marginBottom='12px' style={{ cursor: 'pointer' }} display='flex' alignItems='flex-start'
         justifyContent='space-between' onClick={props.closeSuggestions}>
      <ACOLink href={`/user/${props.user.id}`}>
        <Box display='flex' alignItems='center'>
          <Avatar src={props.user.img} alt={props.user.text} size={{ desktop: 36, mobile: 48 }} />
          <Typography variant='subtitle2' color='textPrimary'
                      style={{ marginLeft: '8px' }}>{props.user.text}</Typography>
        </Box>
      </ACOLink>

      {/*<OnDesktop>
        {loggedUser?.id != props.user.id &&
          renderBtns(stateFollow,
            stateFollow ? 'Dejar de seguir' : 'Seguir',
            stateFollow ? PersonRemoveOutlinedIcon : PersonAddAltIcon,
            stateFollow ? classes.buttonUnfollow : classes.btnFollow,
            stateFollow ? () => unfollow(props.user.id, props.user.text) : () => follow(props.user.id, props.user.text),
          )}
      </OnDesktop>*/}
    </Box>
  );
};

export default UserSearchCard;
