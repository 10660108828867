import ACOLink from '@components/ACOLink/ACOLink';
import SearchIcon from '@mui/icons-material/Search';
import { Box, ClickAwayListener, IconButton, InputBase, Typography } from '@mui/material';
import { OnDesktop, OnMobile, useIsMobile } from '@providers/Responsive.provider';
import { useSearchContext } from '@providers/search/SearchContext';
import clsx, { ClassValue } from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import PreviewResults from './PreviewResults';
import styles from './Search.styles';


interface Props {
  placeholder?: string;
  searcher: (string) => void;
  classes?: ClassValue;
}

const SearchHeader: React.VFC<Props> = (props) => {
  const classes = styles();
  const [searchText, setSearchText] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [cancelSearch, setCancelSearch] = React.useState<boolean>(false);
  const router = useRouter();
  const isMobile = useIsMobile();

  const { updateText, searchText: text } = useSearchContext();

  const handleOpenSearch = () => {
    setOpenModal(true);
  };

  const handleCloseSearch = () => {
    setOpenModal(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    updateText(event.target.value);

    if (event.target.value.length > 2) {
      handleOpenSearch();
    } else {
      handleCloseSearch();
    }

    setSearchText(event.target.value);
  };

  const handleRedirectMobile = () => {
    router.push('/search/mobile');
  };

  useEffect(() => {
    if (cancelSearch) {
      process.env.INCREMENTAL_SEARCH === 'true' &&
      <PreviewResults keyword={searchText} closeSuggestions={handleCloseSearch} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSearch]);

  const desktopSearch = (
    <div className={clsx(classes.search, props.classes)}>
      <InputBase
        placeholder={props.placeholder}
        defaultValue={text != 'undefined' ? text : undefined}
        className={classes.input}
        type='search'
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
        onClick={isMobile ? handleRedirectMobile : undefined}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            console.warn('Tiempo de inicio: ', Date.now(), ' ms');
            setCancelSearch(true);
            props.searcher(searchText);
            handleCloseSearch();
          }
        }}
      />

      <IconButton className={classes.searchIcon}
                  onClick={() => (console.warn('Tiempo de inicio: ', Date.now(), ' ms'), process.env.INCREMENTAL_SEARCH === 'true' && setCancelSearch(true), props.searcher(searchText))}>
        <SearchIcon />
      </IconButton>

      {process.env.INCREMENTAL_SEARCH === 'true' &&
        <OnDesktop>
          <ClickAwayListener onClickAway={handleCloseSearch} disableReactTree>
            <div>
              <div className={classes.searchContainer}>
                {openModal ? (
                  <div className='search'>
                    <Box style={{ padding: '16px 19px' }}>
                      {/*  <Box display='flex' alignItems='flex-start'>
                    <LabelOutlinedIcon />
                    <Typography> Etiquetas </Typography>
                  </Box>
                  <Divider variant='fullWidth' />*/}

                      <PreviewResults keyword={searchText} closeSuggestions={handleCloseSearch} />
                    </Box>

                    <Box className={classes.showMore}>
                      <ACOLink href={`/search/${searchText}`}>
                        <Typography variant='subtitle2'>Ver todos los resultados para [{searchText}] </Typography>
                      </ACOLink>
                    </Box>
                  </div>
                ) : null}
              </div>
            </div>
          </ClickAwayListener>
        </OnDesktop>
      }


    </div>
  );


  return (
    <>
      <OnDesktop>{desktopSearch}</OnDesktop>
      <OnMobile>{desktopSearch}</OnMobile>
    </>
  );
};

SearchHeader.defaultProps = {
  placeholder: 'Buscar...',
};

export default SearchHeader;
