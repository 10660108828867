import ACOLink from '@components/ACOLink/ACOLink';
import { Box, Typography } from '@mui/material';
import { BackendObjectPKG } from '@service/backend/model/objects';
import { ObjectType } from '@service/model';
import React from 'react';

import styles from '../Search.styles';

export interface ObjectsSearchCardProps {
  text: string,
  objectType: ObjectType,
  pkg: string,
  id: number,
  url: string,
  img?: string,
}

interface ObjectSearchProps {
  object: ObjectsSearchCardProps,
  closeSuggestions: () => void
}


const Title = {
  [BackendObjectPKG.TICKET]: () => 'tickets',
  [BackendObjectPKG.POST]: () => 'posts',
  [BackendObjectPKG.DOCUMENT]: () => 'documents',
  [BackendObjectPKG.ONLINE_EVENT]: () => 'events',
};

const ObjectsSearchCard: React.VFC<ObjectSearchProps> = (props) => {

  const classes = styles();

  return (
    <Box marginBottom='12px' style={{ cursor: 'pointer' }} onClick={props.closeSuggestions}>
      <ACOLink href={`/${Title[props.object.pkg] && Title[props.object.pkg](props)}/${props.object.id}`}>
        <Typography variant='body1' color='textPrimary'
                    dangerouslySetInnerHTML={{ __html: props.object.text }} className={classes.textHighlight} />
      </ACOLink>
    </Box>
  );
};

export default ObjectsSearchCard;
