import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '4px 1px 4px 15px',
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
      maxHeight: 40,
    },

    input: {
      width: '100%',
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
      color: theme.palette.text.primary,
    },
    iconButton: {
      padding: '5px',
    },
    searchIcon: {
      color: theme.palette.text.primary,
      fontSize: 20,
    },

    mainBox: {
      [StyleResponsive.onDesktop]: {
        display: 'flex',
        marginTop: '34px',
      },
    },

    text: {
      [StyleResponsive.onMobile]: {
        marginLeft: '8px',
      },
    },

    cardSearch: {
      width: '100%',
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',

      [StyleResponsive.onDesktop]: {
        padding: '22px',
      },
    },

    filter: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },


    searchContainer: {
      display: 'flex',


      [StyleResponsive.onDesktop]: {
        justifyContent: 'center',
        position: 'relative',
      },

      [StyleResponsive.onMobile]: {
        justifyContent: 'flex-end',
      },

      '& > .search': {
        position: 'absolute',
        marginTop: '30px',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '8px',
        backgroundColor: 'white',
        color: theme.palette.text.primary,
        overflowX: 'hidden',

        [StyleResponsive.onMobile]: {
          width: '97vw',
        },

        [StyleResponsive.onDesktop]: {
          width: '450px',
          marginRight: '430px',
        },

      },

      '& > .filterUbication': {
        marginTop: 30,
        width: '370px',
        minHeight: '270px',
        position: 'absolute',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'scroll',
        overflowX: 'hidden',
        marginRight: 375,
        color: theme.palette.text.primary,
        '& > .p': {
          '& svg': {
            marginRight: 10,
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.secondary.main,
          fontSize: 16,
          padding: '14px 25px 14px 20px',
          '&:hover': {
            backgroundColor: 'rgba(34, 112, 168, 0.1);',
          },
        },
      },

      '& > .suggestions': {
        minHeight: '20px',

        marginTop: '2px',
        zIndex: 1,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: '8px',
        backgroundColor: 'white',
        color: theme.palette.text.primary,
        overflowX: 'hidden',

        [StyleResponsive.onMobile]: {
          width: '100%',
        },

        [StyleResponsive.onDesktop]: {
          width: '100%',
          position: 'absolute',
        },
      },
    },


    kmBtn: {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      boxSizing: 'border-box',
      marginRight: '10px',
      marginBottom: '10px',

      '&:hover': {
        background: theme.palette.secondary.main,
        color: '#FFFFFF',
      },
    },

    kmBtnSelected: {
      border: `2px solid ${theme.palette.secondary.main}`,
      background: theme.palette.secondary.main,
      color: '#FFFFFF',
      boxSizing: 'border-box',
      marginRight: '10px',
      marginBottom: '10px',

      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },

    btnBack: {
      color: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      '&:hover, &$selected, &$selected:hover ': {
        background: theme.palette.secondary.main,
        color: '#FFFFFF',
      },
    },

    btnAccept: {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      width: '75px',
      height: '42px',
      '&:hover, &$selected, &$selected:hover ': {
        background: theme.palette.primary.light,
        color: '#FFFFFF',

      },
    },

    showMore: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      height: '50px',

      '&:hover': {
        cursor: 'pointer',
      },
    },

    buttonUnfollow: {
      height: '36px',
      width: '95px',
      border: `2px solid ${theme.palette.error.main}`,
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.error.main,

      [StyleResponsive.onMobile]: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
      },

      [StyleResponsive.onDesktop]: {
        minWidth: '162px',
      },

      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },

    },

    btnRed: {
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.error.main,
      marginRight: '10px',
      [StyleResponsive.onMobile]: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
      },

      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },
    },


    btnFollow: {
      height: '36px',
      width: '95px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',

      [StyleResponsive.onMobile]: {
        marginTop: '15px',
      },

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },

    },


    modalCategories: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '750px',
      overflowY: 'scroll',
      maxHeight: '90%',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      padding: '16px 19px',

      [StyleResponsive.onMobile]: {
        width: '100%',
        height: '100%',
      },

    },


    chip: {
      marginTop: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      borderRadius: '6px',
      backgroundColor: theme.palette.grey['500'],
      color: theme.palette.text.primary,
      height: '30px',
      minWidth: '90px',
      padding: '5px',
      '& .MuiChip-label': {
        whiteSpace: 'break-spaces',
      },
      [StyleResponsive.onMobile]: {
        '& .MuiChip-label': {
          padding: '3px',
        },
      },
    },

    btnMostWanted: {
      width: '168px',
      height: '28px',
      borderRadius: '6px',
      padding: '4px 12px',
      border: '1px solid #0A803B',
      boxSizing: 'border-box',
      color: theme.palette.primary.dark,
      marginTop: '12px',
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },
    },

    textfield: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    location: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',

      '& > .location': {
        width: '100%',
        padding: '12px 8px',
        position: 'absolute',
        zIndex: 100,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 8,
        backgroundColor: 'white',
        overflow: 'hidden',

        '& > .option': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 0px',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '& > .iconLocation': {
            color: theme.palette.primary.main,
          },
        },
      },
    },

    textHighlight: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    chipLocation: {
      background: theme.palette.secondary.main,
      color: theme.palette.text.disabled,
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      justifyContent: 'space-between',
      [StyleResponsive.onDesktop]: {
        marginTop: '5px',
        marginBottom: '5px',
      },
      [StyleResponsive.onMobile]: {
        width: '100%',
      },
    },


    boxCategories: {
      display: 'flex',
      padding: '8px',
      border: '1px solid #3A3A3A',
      borderRadius: '6px',
      justifyContent: 'center',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
      },
    },

    chipTag: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: alpha(theme.palette.primary.main, 0.2),
      borderRadius: '6px',
      color: theme.palette.primary.main,

      [StyleResponsive.onDesktop]: {
        minHeight: '28px',
        padding: '4px 12px',
        marginLeft: '4px',
      },

      [StyleResponsive.onMobile]: {
        height: '20px',
        marginLeft: '5px',
      },


      '&.noSelect': {
        '& .MuiChip-deleteIcon': {
          display: 'none',
        },
      },

      '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,
        '&:hover': {
          color: alpha(theme.palette.primary.main, 0.7),
        },
      },
    },


    content: {
      overflowY: 'auto',
      [StyleResponsive.onMobile]: {
        height: '80vh',
      },
      [StyleResponsive.onDesktop]: {
        paddingBottom: '10px',
        height: '100%',
      },
    },

    suggestion: {
      padding: '6px 19px',
      textTransform: 'capitalize',

      '&:hover': {
        backgroundColor: 'rgba(34, 112, 168, 0.1);',
        cursor: 'pointer',
      },
    },

    root: {
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxShadow: '0px 2px 6px #DCDCDC',
      display: 'flex',
      padding: '7px',
      alignItems: 'center',
      justifyContent: 'space-between',

      [StyleResponsive.onDesktop]: {
        height: '75px',
      },

      '&:hover': {
        overflow: 'visible',
        borderRadius: 8,
        '& .container': {
          borderRadius: 8,
          boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
        },
        cursor: 'pointer',
      },
    },

    test: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },

    subcategory: {
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxShadow: '0px 2px 6px #DCDCDC',
      display: 'flex',
      padding: '15px',
      alignItems: 'center',
      justifyContent: 'space-between',

      [StyleResponsive.onDesktop]: {
        height: '60px',
      },

      '&:hover': {
        overflow: 'visible',
        borderRadius: 8,
        '& .container': {
          borderRadius: 8,
          boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
        },
        cursor: 'pointer',
      },
    },


  }),
);
