import { Thematic, ThematicExpert } from '@service/model';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

interface SearchContextInteface {
  updateText: (text: string) => void;
  searchText: string;
  /*TODO: Ubicacion, distancia y categoria*/
  thematic: Thematic;
  subcategory: Thematic;
  updateThematic: (newCategory: Thematic | ThematicExpert) => void;
  updateSubcategory: (newSubcategory: Thematic | ThematicExpert) => void;
  thematicID: number;
  updateThematicID: (newThematicID: number) => void;
  completeThematic: Thematic | ThematicExpert;
  updateCompleteThematic: (newCategory: Thematic | ThematicExpert) => void;

}

const useUpdateSearchText: (string) => SearchContextInteface = (text: string) => {
  const [searchText, setSearchText] = React.useState<string>(text);

  const [thematic, setThematic] = useState<Thematic>(null);
  const [subcategory, setSubcategory] = useState<Thematic>(null);
  const [thematicID, setThematicID] = React.useState(null);
  const [completeThematic, setCompleteThematic] = useState<Thematic>(null);


  const updateText = (text: string) => {
    setSearchText(text);
  };

  const updateThematic = (newThematic: Thematic) => {
    setThematic(newThematic);
  };

  const updateSubcategory = (newSubcategory: Thematic) => {
    setSubcategory(newSubcategory);
  };

  const updateThematicID = (newvalue: number) => {
    setThematicID(newvalue);
  };

  const updateCompleteThematic = (newThematic: Thematic) => {
    setCompleteThematic(newThematic);
  };

  return {
    updateText,
    searchText,
    thematic,
    subcategory,
    updateSubcategory,
    updateThematic,
    updateThematicID,
    thematicID,
    completeThematic,
    updateCompleteThematic,
  };
};


const SearchProvider: React.FC<PropsWithChildren<{ text?: string }>> = ({ children, text }) => {
  const currentText = useUpdateSearchText(text);

  return (
    <SearchContext.Provider value={currentText}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
export const SearchContext = createContext<SearchContextInteface>(null);

export const useSearchContext = () => useContext(SearchContext);


