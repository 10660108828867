import { LinkProps } from 'next/dist/client/link';
import Link from 'next/link';
import React, { CSSProperties, PropsWithChildren } from 'react';

interface ACOLinkProps extends LinkProps {
  style?: CSSProperties | undefined;
  className?: string | undefined;
}

const ACOLink: React.FC<PropsWithChildren<ACOLinkProps>> = (props) => {
  return (
    <Link {...props as LinkProps}>
      <div style={{cursor: 'pointer', ...props.style}} className={props.className}>
        {props.children}
      </div>
    </Link>
  )
};

export default ACOLink;